import React, { ReactNode, memo, useEffect, useState } from 'react'
import { LoginWrapper } from './style'
import { useAppDispatch } from '@/store'
import { changeLoginPanel } from '@/store/module/Playing'
import {
  getConstructBase64,
  getConstructKey,
  getKeyStatus,
  getLoginStatus
} from './service'
import Cookies from 'js-cookie'
import { formatCookie } from '@/utils/format'
interface IProps {
  children?: ReactNode
}
const login: React.FC<IProps> = () => {
  const [baseimg, setBaseimg] = useState<string>('')
  const [key, setKey] = useState<string>('')
  useEffect(() => {
    getConstructKey().then((res) => {
      setKey(res.data.unikey)
      getConstructBase64(res.data.unikey).then((res) => {
        setBaseimg(res.data.qrimg)
      })
    })
  }, [])
  useEffect(() => {
    const timer = setInterval(() => {
      getKeyStatus(key).then(async (res) => {
        if (res.code === 803) {
          try {
            formatCookie(res.cookie).forEach((item) => {
              Cookies.set(item.key, item.value)
            })
          } catch (error) {
            console.log(error)
          }
          dispatch(changeLoginPanel(false))
        }
      })
    }, 2000)
    return () => clearInterval(timer)
  })
  const dispatch = useAppDispatch()
  const handleLoginPanel = () => {
    dispatch(changeLoginPanel(false))
  }
  return (
    <LoginWrapper>
      <div className="head">
        <span className="logintext">登录</span>
        <i onClick={handleLoginPanel}>x</i>
      </div>
      <div className="logincontent">
        <div className="left">
          <img
            src="https://p5.music.126.net/obj/wo3DlcOGw6DClTvDisK1/9643571155/525c/faac/2dc6/fe695c03c7c358ddaa4651736b26a55f.png"
            alt=""
          />
        </div>
        <div className="right">
          <div className="title">扫码登陆</div>
          <div className="canvas">
            <img src={baseimg} alt="" />
          </div>
          <div className="order">
            使用<a>网易云音乐APP</a>扫码登录
          </div>
        </div>
      </div>
    </LoginWrapper>
  )
}

export default memo(login)
