import {
  getArtistCategory,
  getHotArtist
} from '@/views/discover/c-views/artist/service'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
export const fetchHotArtistAction = createAsyncThunk(
  'hotartist',
  async (arg, { dispatch }) => {
    const res = await getHotArtist()
    dispatch(changeArtistList(res.artists))
  }
)
export const fetchAllArtistAction = createAsyncThunk(
  'allartist',
  async (info: any, { dispatch }) => {
    const res = await getArtistCategory(info.type, info.area)
    dispatch(changeArtistList(res.artists))
  }
)
interface IArtist {
  artistList: any[]
}
const initialState: IArtist = {
  artistList: []
}
const artistSlice = createSlice({
  name: 'artist',
  initialState,
  reducers: {
    changeArtistList(state, { payload }) {
      state.artistList = payload
    }
  }
})

export const { changeArtistList } = artistSlice.actions

export default artistSlice.reducer
