import { getSongData, getSongLyric } from '@/components/player/service'
import { parseLyric } from '@/utils/parse-lyric'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const fetchCurrentSongAction = createAsyncThunk(
  'player',
  async (id: number, { dispatch }) => {
    const res1 = await getSongData(id)
    await dispatch(changeCurrentSongAction(res1?.songs[0]))
    const res2 = await getSongLyric(id)
    await dispatch(changeLyircDataAction(parseLyric(res2.lrc.lyric)))
    return res1?.songs[0]
  }
)
interface IPlayer {
  currentSong: any
  lyirc: any
  playList: any
  currentTime: number
  playListIndex: number
}
const initialState: IPlayer = {
  currentSong: {},
  lyirc: [],
  playList: [],
  currentTime: 0,
  playListIndex: 0
}
const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    changeCurrentSongAction(state, { payload }) {
      state.currentSong = payload
    },
    changeLyircDataAction(state, { payload }) {
      state.lyirc = payload
    },
    changeCurrentTimeAction(state, { payload }) {
      state.currentTime = payload
    },
    changePlayListAction(state, { payload }) {
      state.playList = payload
    },
    changePlayListIndexAtion(state, { payload }) {
      state.playListIndex = payload
    }
  }
})

export const {
  changeCurrentSongAction,
  changeLyircDataAction,
  changeCurrentTimeAction,
  changePlayListAction,
  changePlayListIndexAtion
} = playerSlice.actions
export default playerSlice.reducer
