import styled from 'styled-components'

export const AppFooterWrapper = styled.div`
  width: 100%;
  border-top: 1px solid #d3d3d3;
  .navlist {
    display: flex;
    justify-content: space-around;
    margin-top: 33px;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      .foot_enter {
        cursor: pointer;
      }
      .logo1 {
        background-position: -170px -5px;
        &:hover {
          background-position: -5px -115px;
        }
      }
      .logo2 {
        background-position: -5px -170px;
        &:hover {
          background-position: -60px -170px;
        }
      }
      .logo3 {
        background-position: -5px -60px;
        &:hover {
          background-position: -60px -5px;
        }
      }
      .logo4 {
        display: block;
        width: 45px;
        height: 45px;
        background: url(${require('@/assets/img/xStudio.png')});
        background-size: 45px 45px;
        &:hover {
          background-image: url(${require('@/assets/img/xStudioHover.png')});
        }
      }
      .logo5 {
        background-position: -60px -60px;
        &:hover {
          background-position: -115px -5px;
        }
      }
      .logo6 {
        background-position: -115px -115px;
        &:hover {
          background-position: -5px -5px;
        }
      }
      .logo7 {
        display: block;
        width: 45px;
        height: 45px;
        background: url(${require('@/assets/img/cloudSong.png')});
        background-size: 45px 45px;
        &:hover {
          background-image: url(${require('@/assets/img/cloudSongHover.png')});
        }
      }
      .logo8 {
        background-position: -170px -115px;
        &:hover {
          background-position: -60px -115px;
        }
      }
      span {
        height: 40px;
        line-height: 40px;
        font-size: 13px;
        color: #666;
      }
    }
  }
  .bottom {
    margin: 50px 0;
    ul {
      display: flex;
      width: 800px;
      margin: 0 auto;
      align-items: center;
      font-size: 4px;
      color: #999;
      li {
        padding: 0 20px;
        font-size: 12px;
        color: #666;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .info {
      display: flex;
      justify-content: center;
      color: #666;
      width: 600px;
      margin: 0 auto;
      height: 50px;
      line-height: 50px;
      font-size: 12px;
    }
  }
`
