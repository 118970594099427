import { getTrackDetail } from '@/views/discover/c-views/track/service'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const fetchTrackListAction = createAsyncThunk(
  'track',
  async (id: number, { dispatch }) => {
    const res = await getTrackDetail(id)
    dispatch(changeTrackDetail(res?.playlist))
  }
)
interface ITrack {
  track: any
}
const initialState: ITrack = {
  track: {}
}
const trackSlice = createSlice({
  name: 'strack',
  initialState,
  reducers: {
    changeTrackDetail(state, { payload }) {
      state.track = payload
    }
  }
})

export const { changeTrackDetail } = trackSlice.actions

export default trackSlice.reducer
