import hyRequest from '@/service'

export function getSearchList(
  keywords: string,
  limit?: number,
  offset?: number
) {
  return hyRequest.get({
    url: '/cloudsearch?keywords=' + keywords,
    params: {
      type: 1,
      limit,
      offset
    }
  })
}
