import { getRankingList } from '@/views/discover/c-views/ranking/service'
import { getPlaylistDetail } from '@/views/discover/c-views/recommend/service'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const fetchRankingListActioin = createAsyncThunk(
  'ranking',
  async (arg, { dispatch }) => {
    const res = await getRankingList()
    dispatch(changeRankingList(res.list))
  }
)
export const fetchRankingDetailAction = createAsyncThunk(
  'detail',
  async (id: number, { dispatch }) => {
    const res = await getPlaylistDetail(id)
    dispatch(changeRankingDetail(res))
  }
)

interface IRanking {
  rankingList: any[]
  rankingDetail: any
  rankingId: number
}
const initialState: IRanking = {
  rankingList: [],
  rankingDetail: {},
  rankingId: 19723756
}
const rankingSlice = createSlice({
  name: 'ranking',
  initialState,
  reducers: {
    changeRankingList(state, { payload }) {
      state.rankingList = payload
    },
    changeRankingDetail(state, { payload }) {
      state.rankingDetail = payload
    },
    changeRankingId(state, { payload }) {
      state.rankingId = payload
    }
  }
})

export const { changeRankingList, changeRankingDetail, changeRankingId } =
  rankingSlice.actions

export default rankingSlice.reducer
