import styled from 'styled-components'

export const LoginWrapper = styled.div`
  position: fixed;
  width: 530px;
  height: 366px;
  border-radius: 4px 4px 0px 0px;
  top: 40%;
  left: 50%;
  margin-left: -265px;
  margin-top: -183px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 5px 16px #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  .head {
    width: 100%;
    height: 38px;
    line-height: 38px;
    padding: 0px 20px;
    background-color: #2d2d2d;
    box-sizing: border-box;
    border-bottom: 1px solid #191919;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 14px;
    i {
      font-style: normal;
      font-size: 20px;
      color: #d2d2d2;
      cursor: pointer;
    }
  }
  .logincontent {
    width: 528px;
    height: 220px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .left {
      width: 125px;
      height: 223px;
      img {
        width: 125px;
        height: 220px;
      }
    }
    .right {
      width: 200px;
      height: 213px;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        height: 40px;
        line-height: 40px;
        font-size: 18px;
      }
      .order {
        font-size: 12px;
        height: 30px;
        line-height: 30px;
        a {
          cursor: pointer;
          color: #000099;
        }
      }
    }
  }
`
