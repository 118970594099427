import hyRequest from '@/service'

export function getRankingList() {
  return hyRequest.get({
    url: '/toplist'
  })
}

export function getRankingDetail() {
  return hyRequest.get({
    url: '/toplist/detail'
  })
}
