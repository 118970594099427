import React, { Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
import routes from './router'
import AppHeader from './components/app-header'
import AppFooter from './components/app-footer'
import Player from './components/player'
import Lyric from './components/Lyric'
import { useSelector } from 'react-redux'
import { IRootState, useAppDispatch } from './store'
import { fetchCurrentSongAction } from './store/module/player'
import Login from './components/login'
import { ConfigProvider, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
export const audio = new Audio()
function App() {
  const dispatch = useAppDispatch()
  const { currentSong } = useSelector(
    (state: IRootState) => state.persistedReducer.player
  )
  if (!currentSong.name) {
    dispatch(fetchCurrentSongAction(2091964232))
  }
  const { loginPanel } = useSelector((state: IRootState) => state.isPlaying)
  return (
    <div className="App">
      <AppHeader />
      <Suspense
        fallback={
          <ConfigProvider
            theme={{
              components: {
                Spin: {
                  contentHeight: 400,
                  dotSize: 100
                }
              }
            }}
          >
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </ConfigProvider>
        }
      >
        <div className="main">{useRoutes(routes)}</div>
      </Suspense>
      <AppFooter />
      <Player audio={audio} />
      <Lyric />
      {loginPanel && <Login />}
    </div>
  )
}
export default App
