import { getSearchList } from '@/views/search/service'
import { IKeyWord } from '@/views/search/types'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const fetchSearchListAction = createAsyncThunk(
  'searchlist',
  async (info: IKeyWord, { dispatch }) => {
    const res = await getSearchList(info.keyword, info.limit, info.offset)
    dispatch(changeSearchList(res?.result?.songs))
    dispatch(changeSongCount(res.result.songCount))
  }
)
interface ISearch {
  searchList: Array<any>
  keyword: string
  songCount: number
}
const initialState: ISearch = {
  searchList: [],
  keyword: '',
  songCount: 0
}
const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    changeSearchList(state, { payload }) {
      state.searchList = payload
    },
    changeKeywords(state, { payload }) {
      state.keyword = payload
    },
    changeSongCount(state, { payload }) {
      state.songCount = payload
    }
  }
})

export const { changeSearchList, changeKeywords, changeSongCount } =
  searchSlice.actions

export default searchSlice.reducer
