import hyRequest from '@/service'

export function getHotArtist() {
  return hyRequest.get({
    url: '/top/artists?offset=0&limit=30'
  })
}

export function getArtistCategory(type: number, area: number) {
  return hyRequest.get({
    url: `/artist/list?type=${type}&area=${area}`
  })
}
