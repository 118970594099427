import { combineReducers, configureStore } from '@reduxjs/toolkit'
import counterReducer from './module/conuter'
import recommentReducer from './module/recomment'
import isPlaying from './module/Playing'
import player from './module/player'
import ranking from './module/ranking'
import search from './module/search'
import track from './module/track'
import { useDispatch } from 'react-redux'
import { persistStore, persistReducer } from 'redux-persist'
import storageLocation from 'redux-persist/lib/storage'
import artist from './module/artist'
const persistConfig = {
  key: 'root',
  storage: storageLocation
}
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    counterReducer,
    recommentReducer,
    player,
    ranking,
    search,
    track,
    artist
  })
)
const store = configureStore({
  reducer: {
    persistedReducer,
    isPlaying
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    })
})
type DispatchType = typeof store.dispatch
type GetStateFnType = typeof store.getState
export type IRootState = ReturnType<GetStateFnType>
export const useAppDispatch: () => DispatchType = useDispatch
const persistor = persistStore(store)
export { store, persistor }
