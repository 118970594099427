import React, { ReactNode, memo } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { HeaderWrapper } from './style'
import { ConfigProvider, Input, message } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { IRootState, useAppDispatch } from '@/store'
import { changeKeywords } from '@/store/module/search'
import { changeLoginPanel } from '@/store/module/Playing'

interface IProps {
  children?: ReactNode
}
const AppHeader: React.FC<IProps> = () => {
  const { keyword } = useSelector(
    (state: IRootState) => state.persistedReducer.search
  )
  const navigate = useNavigate()
  const handleClickSearch = (e: any) => {
    e.target.blur()
    navigate('/search')
  }
  const dispatch = useAppDispatch()
  const handlechangeValue = (e: any) => {
    dispatch(changeKeywords(e.target.value))
  }
  const handleLoginPanel = () => {
    dispatch(changeLoginPanel(true))
  }
  const handleCreatorCenter = () => {
    message.error('该功能尚未开放')
  }
  const handleClickHome = () => {
    navigate('/discover/recommend')
  }
  return (
    <HeaderWrapper>
      <div className="top wrapper-v1">
        <div className="logo sprite_01">
          <a onClick={handleClickHome}>仿网易云音乐</a>
        </div>
        <ul className="left">
          <div className="link">
            <NavLink to="/discover">发现音乐</NavLink>
          </div>
          <div className="link">
            <NavLink to="/mine">我的音乐</NavLink>
          </div>
          <div className="link">
            <NavLink to="/focus">关注</NavLink>
          </div>
          <div className="link">
            <NavLink to="/download">下载客户端</NavLink>
          </div>
        </ul>
        <div className="right">
          <div>
            <ConfigProvider
              theme={{
                components: {
                  Input: {
                    activeBorderColor: '#fff'
                  }
                }
              }}
            >
              <Input
                className="input"
                placeholder="音乐/视频/电台/用户"
                prefix={<SearchOutlined />}
                onPressEnter={handleClickSearch}
                onChange={handlechangeValue}
                value={keyword}
              />
            </ConfigProvider>
          </div>
          <div className="center" onClick={handleCreatorCenter}>
            创作者中心
          </div>
          <div className="login" onClick={handleLoginPanel}>
            登录
          </div>
        </div>
      </div>
      <div className="divider"></div>
    </HeaderWrapper>
  )
}

export default memo(AppHeader)
