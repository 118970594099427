import {
  getArtistList,
  getBanners,
  getNewAlbum,
  getPlaylistDetail,
  getRecommentList
} from '@/views/discover/c-views/recommend/service'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const fetchBannerDataAction = createAsyncThunk(
  'banners',
  async (arg, { dispatch }) => {
    const res = await getBanners()
    dispatch(changeBannersAction(res.banners))
  }
)
export const fetchRecommentDataAction = createAsyncThunk(
  'recomment',
  async (arg, { dispatch }) => {
    const res = await getRecommentList()
    dispatch(changeRecommentListAction(res.result))
  }
)
export const fetchAlbumDataAction = createAsyncThunk(
  'album',
  async (arg, { dispatch }) => {
    const res = await getNewAlbum()
    dispatch(changeAlbumListAction(res.data))
  }
)
export const fetchSingerDataAction = createAsyncThunk(
  'singers',
  async (arg, { dispatch }) => {
    const res = await getArtistList()
    dispatch(changeSingersAction(res.artists))
  }
)
const rankingIds = [19723756, 3779629, 2884035]
export const fetchRankingDataAction = createAsyncThunk(
  'rankingData',
  async (_, { dispatch }) => {
    const promises: Promise<any>[] = []
    for (const id of rankingIds) {
      promises.push(getPlaylistDetail(id))
    }
    Promise.all(promises).then((res) => {
      const playlists = res.map((item) => item.playlist)
      dispatch(changeRankingsAction(playlists))
    })
  }
)
interface IRecommendState {
  banners: any[]
  recommentList: any[]
  ablumList: any[]
  rankings: []
  settleSingers: any[]
}
const initialState: IRecommendState = {
  banners: [],
  recommentList: [],
  ablumList: [],
  rankings: [],
  settleSingers: []
}
export const recommentSlice = createSlice({
  name: 'recomment',
  initialState,
  reducers: {
    changeBannersAction(state, { payload }) {
      state.banners = payload
    },
    changeRecommentListAction(state, { payload }) {
      state.recommentList = payload
    },
    changeAlbumListAction(state, { payload }) {
      state.ablumList = payload
    },
    changeRankingsAction(state, { payload }) {
      state.rankings = payload
    },
    changeSingersAction(state, { payload }) {
      state.settleSingers = payload
    }
  }
})

export const {
  changeBannersAction,
  changeRecommentListAction,
  changeAlbumListAction,
  changeRankingsAction,
  changeSingersAction
} = recommentSlice.actions
export default recommentSlice.reducer
