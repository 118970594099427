import React, { ReactNode, memo, useEffect, useRef, useState } from 'react'
import { PlayerWrapper } from './style'
import { Slider, message } from 'antd'
import { getSongPlayUrl } from '@/utils/handle-player'
import { shallowEqual, useSelector } from 'react-redux'
import { IRootState, useAppDispatch } from '@/store'
import { audio } from '@/App'
import {
  changeCurrentTimeAction,
  changePlayListAction,
  changePlayListIndexAtion,
  fetchCurrentSongAction
} from '@/store/module/player'
import { getcheckMusic } from './service'
import { formatImg, formatTime } from '@/utils/format'
import {
  changeAnimateX,
  changeLoginPanel,
  changeLyircPanelAction,
  changeisPlaying
} from '@/store/module/Playing'
import { debounce } from '@/utils/useDebounce'

interface IProps {
  children?: ReactNode
  audio: any
}
const Player: React.FC<IProps> = (props) => {
  const [processTime, setProcessTime] = useState<number>(0)
  const [sliderValue, setSliderValue] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const { currentSong, playList, playListIndex } = useSelector(
    (state: IRootState) => state.persistedReducer.player,
    shallowEqual
  )
  const { isPlaying, lyircPanel } = useSelector(
    (state: IRootState) => state.isPlaying
  )
  const [isPlayList, setIsPlayList] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  useEffect(() => {
    props.audio.autoplay = true
    audio
      ?.play()
      .then(() => {
        dispatch(changeisPlaying(true))
      })
      .catch((err) => {
        // console.log(err)
      })
  }, [])
  audio.ontimeupdate = () => {
    setProcessTime(Math.floor(audio.currentTime * 1000))
    setSliderValue(Math.floor((audio.currentTime * 100000) / currentSong?.dt))
    dispatch(changeCurrentTimeAction(Math.floor(audio.currentTime * 1000)))
  }
  const [playType, setPlayType] = useState<number>(0)
  const playingType = async (index: number) => {
    dispatch(changePlayListIndexAtion(index))
    const id = playList?.[index]?.id
    const result = await getcheckMusic(id)
    if (result.success) {
      dispatch(fetchCurrentSongAction(id))
      audio.src = getSongPlayUrl(id)
      dispatch(changeisPlaying(true))
    } else {
      message.info('该歌曲没有版权')
      dispatch(changeisPlaying(false))
    }
  }
  audio.onended = async () => {
    if (playType === 0) {
      if (playListIndex < playList.length - 1) {
        playingType(playListIndex + 1)
      } else {
        message.info('播放列表已播放完')
        dispatch(changeisPlaying(false))
      }
    } else if (playType === 1) {
      const index = Math.floor(Math.random() * playList.length)
      console.log(index)
      playingType(index)
    } else {
      playingType(playListIndex)
    }
  }

  function handleControl() {
    if (loading) {
      props.audio.src = getSongPlayUrl(currentSong?.id)
      setLoading(false)
    }
    isPlaying ? audio?.pause() : audio?.play()
    dispatch(changeisPlaying(!isPlaying))
  }
  function changeSlider(value: number) {
    setSliderValue(value)
    audio.currentTime = (Math.floor(currentSong?.dt / 1000) * value) / 100
  }
  function handleLyircPanel() {
    dispatch(changeLyircPanelAction(!lyircPanel))
  }
  function handleisPlayList(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation()
    setIsPlayList(!isPlayList)
  }
  async function switchSong(event: any, index: number) {
    const id = event.currentTarget.dataset.id
    const result = await getcheckMusic(id)
    if (result.success) {
      dispatch(fetchCurrentSongAction(id))
      audio.src = getSongPlayUrl(id)
      dispatch(changeisPlaying(true))
      dispatch(changePlayListIndexAtion(index))
    } else {
      message.info('该歌曲没有版权')
    }
    setIsPlayList(false)
  }
  const clickWindow = () => {
    setIsPlayList(false)
  }
  useEffect(() => {
    window.addEventListener('click', clickWindow)
    return () => window.removeEventListener('click', clickWindow)
  })
  async function handleNextSong() {
    if (playListIndex < playList.length - 1) {
      dispatch(changePlayListIndexAtion(playListIndex + 1))
      const id = playList?.[playListIndex + 1]?.id
      const result = await getcheckMusic(id)
      if (result.success) {
        dispatch(fetchCurrentSongAction(id))
        audio.src = getSongPlayUrl(id)
        dispatch(changeisPlaying(true))
      } else {
        message.info('该歌曲没有版权')
      }
    } else {
      message.info('播放列表已播放完')
    }
  }
  async function handlePreSong() {
    if (playListIndex > 0) {
      dispatch(changePlayListIndexAtion(playListIndex - 1))
      const id = playList?.[playListIndex - 1]?.id
      const result = await getcheckMusic(id)
      if (result.success) {
        dispatch(fetchCurrentSongAction(id))
        audio.src = getSongPlayUrl(id)
        dispatch(changeisPlaying(true))
      } else {
        message.info('该歌曲没有版权')
      }
    } else {
      message.info('无歌曲播放')
    }
  }
  function handleClearPlayList() {
    dispatch(changePlayListAction([]))
  }
  const handleplayType = () => {
    if (playType < 2) {
      setPlayType(playType + 1)
    } else {
      setPlayType(0)
    }
  }
  const listRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    dispatch(changeAnimateX(listRef.current?.offsetLeft))
    window.addEventListener('resize', () =>
      dispatch(changeAnimateX(listRef.current?.offsetLeft))
    )
    return window.removeEventListener('resize', () =>
      dispatch(changeAnimateX(listRef.current?.offsetLeft))
    )
  })
  const [volumExit, setVolumExit] = useState<boolean>(false)
  const [volumValue, setVolumValue] = useState<number>(100)
  const handleChangeVolum = (value: number) => {
    audio.volume = value / 100
    setVolumValue(value)
  }
  const handleShowPanel = () => {
    setVolumExit(!volumExit)
  }
  const handleShowLogin = () => {
    message.error('请先登录！')
    dispatch(changeLoginPanel(true))
  }
  return (
    <PlayerWrapper>
      <div className="player wrapper-v2">
        <div className="play">
          <div className="pre playbar_sprite" onClick={handlePreSong}></div>
          <div
            className={
              isPlaying
                ? 'pauel playbar_sprite pauel_active'
                : 'pauel playbar_sprite'
            }
            onClick={handleControl}
          ></div>
          <div className="next playbar_sprite" onClick={handleNextSong}></div>
        </div>
        <div className="img" onClick={handleLyircPanel}>
          <img src={formatImg(currentSong?.al?.picUrl)} alt="" />
          <div className="bg playbar_sprite"></div>
        </div>
        <div className="p-detail">
          <div className="process_top">
            <div className="p-name">{currentSong?.name}</div>
            <div className="p-artist">{currentSong?.ar?.[0]?.name}</div>
          </div>
          <div className="p-bottom">
            <Slider
              value={sliderValue}
              step={0.2}
              tooltip={{ formatter: null }}
              onChange={changeSlider}
            />
            <div className="s-time">
              <span className="start-time">{formatTime(processTime)}</span>
              <span className="end-time">/ {formatTime(currentSong?.dt)}</span>
            </div>
          </div>
        </div>
        <div className="iconlist1">
          <div className="lyic-icon" onClick={handleLyircPanel}></div>
          <div className="like-icon" onClick={handleShowLogin}></div>
          <div className="share-icon" onClick={handleShowLogin}></div>
        </div>
        <span className="divider"></span>
        <div className="iconlist2">
          <div className="vol" onClick={handleShowPanel}></div>
          {volumExit && (
            <div className="volunm">
              <Slider
                defaultValue={volumValue}
                vertical
                onChange={debounce(handleChangeVolum, 500)}
              />
            </div>
          )}
          {playType === 0 && (
            <div className="icv" onClick={handleplayType}></div>
          )}
          {playType === 1 && (
            <div className="scv" onClick={handleplayType}></div>
          )}
          {playType === 2 && (
            <div className="ccv" onClick={handleplayType}></div>
          )}
          <div
            ref={listRef}
            className="icn"
            onClick={(e) => handleisPlayList(e)}
          >
            {playList?.length}
          </div>
          {
            <div
              style={{ height: isPlayList ? '400px' : '0px' }}
              className="playList"
            >
              <div
                className="p-head"
                style={{ height: isPlayList ? '36px' : '0px' }}
              >
                <span className="list">播放列表({playList?.length})</span>
                <span className="clear" onClick={handleClearPlayList}>
                  清除
                </span>
              </div>
              <ul>
                {playList?.map((item: any, index: number) => {
                  return (
                    <li key={index} className="list-item">
                      <span className="num">{index + 1}</span>
                      <div
                        className="song-detail"
                        data-id={item.id}
                        onClick={(e) => switchSong(e, index)}
                      >
                        {item.name}
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          }
        </div>
      </div>
    </PlayerWrapper>
  )
}

export default memo(Player)
