import dayjs from 'dayjs'

export const formatPlayCount = (number: number) => {
  if (number > 10000) {
    return Math.floor(number / 10000)
  } else {
    return number
  }
}

export const formatImg = (url: string) => {
  return url + '?param=34y34'
}
export const formatTime = (time: number) => {
  const mintue =
    String(Math.floor(time / 1000 / 60)).length === 2
      ? Math.floor(time / 1000 / 60)
      : '0' + String(Math.floor(time / 1000 / 60))
  const seconds =
    String(Math.floor((time / 1000) % 60)).length === 2
      ? Math.floor((time / 1000) % 60)
      : '0' + String(Math.floor((time / 1000) % 60))
  return mintue + ':' + seconds
}

export const formatDate = (date: number) => {
  const year = dayjs(date).year()
  const month = dayjs(date).month() + 1
  const day = dayjs(date).day()
  return year + '-' + month + '-' + day
}

export function formatCookie(cookie: string) {
  const cookieArray: any[] = []
  Array.from(
    new Set(
      cookie.split(';').filter((item) => {
        return (
          item.startsWith('MUSIC') ||
          item.startsWith('__csrf') ||
          item.startsWith('NM')
        )
      })
    )
  ).forEach((res) => {
    cookieArray.push({ key: res.split('=')[0], value: res.split('=')[1] })
  })
  return cookieArray
}
