import React, { ReactNode, memo } from 'react'
import { AppFooterWrapper } from './style'
import { message } from 'antd'

interface IProps {
  children?: ReactNode
}
const AppFooter: React.FC<IProps> = () => {
  const handleFootClick = () => {
    message.info('此功能尚未开放')
  }
  const handleClickBottom = () => {
    message.info('敬请期待')
  }
  return (
    <AppFooterWrapper>
      <div className="navlist wrapper-v2">
        <div className="item">
          <a className="logo1 foot_enter" onClick={handleFootClick}></a>
          <span>音乐开放平台</span>
        </div>
        <div className="item">
          <a className="logo2 foot_enter" onClick={handleFootClick}></a>
          <span>云村交易所</span>
        </div>
        <div className="item">
          <a className="logo3 foot_enter" onClick={handleFootClick}></a>
          <span>Amped Studio</span>
        </div>
        <div className="item xStudioHover">
          <a className="logo4 foot_enter" onClick={handleFootClick}></a>
          <span>X Studio虚拟歌手</span>
        </div>
        <div className="item">
          <a className="logo5 foot_enter" onClick={handleFootClick}></a>
          <span>用户认证</span>
        </div>
        <div className="item">
          <a className="logo6 foot_enter" onClick={handleFootClick}></a>
          <span>音乐交易平台</span>
        </div>
        <div className="item">
          <a className="logo7 foot_enter" onClick={handleFootClick}></a>
          <span>云推歌</span>
        </div>
        <div className="item">
          <a className="logo8 foot_enter" onClick={handleFootClick}></a>
          <span>赞赏</span>
        </div>
      </div>
      <div className="bottom">
        <div className="link-nav">
          <ul className="link">
            <li onClick={handleClickBottom}>服务条款</li>|
            <li onClick={handleClickBottom}>隐私政策</li>|
            <li onClick={handleClickBottom}>儿童隐私政策</li>|
            <li onClick={handleClickBottom}>版权服务</li>|
            <li onClick={handleClickBottom}>投资者关系</li>|
            <li onClick={handleClickBottom}>广告合作</li>|
            <li onClick={handleClickBottom}>联系我们</li>
          </ul>
        </div>
        <div className="info">此网站为仿网易云音乐</div>
      </div>
    </AppFooterWrapper>
  )
}

export default memo(AppFooter)
