import { createSlice } from '@reduxjs/toolkit'

const counterSlice = createSlice({
  name: 'isplaying',
  initialState: {
    isPlaying: false,
    lyircPanel: false,
    loginPanel: false,
    animateX: 0
  },
  reducers: {
    changeisPlaying(state, { payload }) {
      state.isPlaying = payload
    },
    changeLyircPanelAction(state, { payload }) {
      state.lyircPanel = payload
    },
    changeLoginPanel(state, { payload }) {
      state.loginPanel = payload
    },
    changeAnimateX(state, { payload }) {
      state.animateX = payload
    }
  }
})

export const {
  changeisPlaying,
  changeLyircPanelAction,
  changeLoginPanel,
  changeAnimateX
} = counterSlice.actions

export default counterSlice.reducer
