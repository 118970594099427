import React, { ReactNode, memo, useEffect } from 'react'
import { LyircWrapper } from './style'
import { shallowEqual, useSelector } from 'react-redux'
import { IRootState } from '@/store'
interface IProps {
  children?: ReactNode
}
const Lyirc: React.FC<IProps> = () => {
  const {
    currentSong,
    lyirc = [],
    currentTime
  } = useSelector(
    (state: IRootState) => state.persistedReducer.player,
    shallowEqual
  )
  const { lyircPanel } = useSelector((state: IRootState) => state.isPlaying)
  let currentIndex = lyirc?.length - 1
  for (let i = 0; i < lyirc.length; i++) {
    if (lyirc[i].time > currentTime) {
      currentIndex = i - 1
      break
    }
  }
  useEffect(() => {
    if (lyircPanel) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [lyircPanel])
  const width = window.screen.width
  return (
    <LyircWrapper
      style={{ transform: `translateY(${lyircPanel ? '0' : '100%'})` }}
      $url={currentSong?.al?.picUrl + '?param=' + width + 'y' + width}
    >
      <div className="cover">
        <div
          className="lyric"
          style={{ transform: `translateY(${-20 * currentIndex}px)` }}
        >
          {lyirc?.map(
            (
              item: {
                time: number
                text: string
              },
              index: number
            ) => {
              return (
                <p
                  key={index}
                  className={index == currentIndex ? 'active' : ''}
                >
                  {item?.text}
                </p>
              )
            }
          )}
        </div>
      </div>
    </LyircWrapper>
  )
}

export default memo(Lyirc)
