import styled from 'styled-components'

export const PlayerWrapper = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 53px;
  background: url(${require('@/assets/img/playbar_sprite.png')}) repeat;
  min-width: 980px;
  z-index: 999;
  .player {
    display: flex;
    align-items: center;
    margin-top: 5px;
    cursor: pointer;
    .img {
      width: 34px;
      height: 34px;
      overflow: hidden;
      border-radius: 5px;
      position: relative;
      .bg {
        width: 34px;
        height: 35px;
        position: absolute;
        top: 0px;
        left: 0px;
        background-position: 0 -80px;
      }
    }
    .play {
      display: flex;
      align-items: center;
      height: 47px;
      .pre {
        width: 28px;
        height: 28px;
        margin-right: 8px;
        display: block;
        margin-top: 5px;
        background-position: 0 -130px;
        cursor: pointer;
        &:hover {
          background-position: -30px -130px;
        }
      }
      .next {
        width: 28px;
        height: 28px;
        margin-right: 8px;
        display: block;
        margin-top: 5px;
        background-position: -80px -130px;
        cursor: pointer;
        &:hover {
          background-position: -110px -130px;
        }
      }
      .pauel {
        width: 36px;
        height: 36px;
        margin-right: 8px;
        display: block;
        margin-top: 0px;
        background-position: 0 -204px;
        cursor: pointer;
        &:hover {
          background-position: -40px -204px;
        }
      }
      .pauel_active {
        background-position: 0 -165px;
        &:hover {
          background-position: -40px -165px;
        }
      }
    }
    .p-detail {
      height: 37px;
      width: 580px;
      margin-left: 10px;
      font-size: 12px;
      align-items: center;
      margin-top: -10px;
      .process_top {
        display: flex;
        height: 28px;
        line-height: 28px;
        .p-name {
          color: #eee;
          margin: 0 15px;
        }
        .p-artist {
          color: #9b9b9b;
        }
      }
      .p-bottom {
        display: flex;
        align-items: center;
        margin-top: -10px;
        .ant-slider {
          position: relative;
          top: -3px;
          width: 453px;
          margin-right: 10px;
          .ant-slider-rail {
            height: 9px;
            background: url(${require('@/assets/img/progress_bar.png')}) right 0;
          }
          .ant-slider-track {
            height: 9px;
            background: url(${require('@/assets/img/progress_bar.png')}) left -66px;
          }
          .ant-slider-handle {
            width: 22px;
            height: 24px;
            border: none;
            margin-top: -5px;
            background: url(${require('@/assets/img/sprite_icon.png')}) 0 -250px;
            &::after {
              display: none;
            }
          }
        }
        .s-time {
          font-size: 12px;
          display: flex;
          .start-time {
            color: #a1a1a1;
          }
          .end-time {
            color: #797979;
            margin-left: 4px;
          }
        }
      }
    }
    .iconlist1 {
      display: flex;
      width: 87px;
      justify-content: space-between;
      margin-left: 20px;
      .lyic-icon {
        width: 25px;
        height: 25px;
        background-image: url(${require('@/assets/img/lyic.PNG')});
        &:hover {
          background-position: 0 -25px;
        }
      }
      .like-icon {
        width: 25px;
        height: 25px;
        background-image: url(${require('@/assets/img/playbar_sprite.png')});
        background-position: -88px -163px;
        &:hover {
          background-position: -88px -189px;
        }
      }
      .share-icon {
        width: 25px;
        height: 25px;
        background-image: url(${require('@/assets/img/playbar_sprite.png')});
        background-position: -114px -163px;
        &:hover {
          background-position: -114px -189px;
        }
      }
    }
    .divider {
      width: 1.5px;
      height: 18px;
      background-color: #555;
      margin: 0 10px;
    }
    .iconlist2 {
      width: 126px;
      display: flex;
      justify-content: space-between;
      .vol {
        width: 25px;
        height: 25px;
        background-image: url(${require('@/assets/img/playbar_sprite.png')});
        background-position: -2px -248px;
        &:hover {
          background-position: -31px -248px;
        }
      }
      .volunm {
        width: 32px;
        height: 116px;
        position: fixed;
        bottom: 44px;
        background: url(${require('@/assets/img/playbar_sprite.png')});
        background-position: 0 -503px;
        margin-left: -5px;
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-slider {
          height: 90px;
          margin-top: 15px;
          .ant-slider-track {
            background-image: url(${require('@/assets/img/playbar_sprite.png')});
            background-position: -40px bottom;
          }
          .ant-slider-handle {
            width: 22px;
            height: 24px;
            border: none;
            margin-left: -4px;
            background: url(${require('@/assets/img/sprite_icon.png')}) -40px -250px;
            &::after {
              display: none;
            }
          }
        }
      }
      .icv,
      .scv,
      .ccv {
        width: 25px;
        height: 25px;
        background-image: url(${require('@/assets/img/playbar_sprite.png')});
      }
      .icv {
        background-position: -3px -344px;
        &:hover {
          background-position: -33px -344px;
        }
      }
      .scv {
        background-position: -66px -248px;
        &:hover {
          background-position: -93px -248px;
        }
      }
      .ccv {
        background-position: -66px -344px;
        &:hover {
          background-position: -93px -344px;
        }
      }
      .icn {
        width: 59px;
        height: 25px;
        line-height: 27px;
        text-align: center;
        background-image: url(${require('@/assets/img/playbar_sprite.png')});
        background-position: -42px -68px;
        font-size: 12px;
        color: #666;
        text-shadow: 0 1px 0 #080707;
        padding-left: 21px;
        box-sizing: border-box;
        &:hover {
          background-position: -42px -98px;
        }
      }
      .playList {
        width: 250px;
        background-color: #fff;
        position: fixed;
        bottom: 47px;
        background-color: #121212;
        overflow-y: scroll;
        box-sizing: border-box;
        z-index: 99;
        transition: height 0.2s;
        &::-webkit-scrollbar {
          width: 4px;
          height: 0px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.9);
          background-color: #333;
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.9);
          border-radius: 5px;
          background-color: #d3dce6;
        }
        .p-head {
          width: 250px;
          height: 36px;
          line-height: 36px;
          color: #fff;
          font-size: 12px;
          display: flex;
          justify-content: space-between;
          padding: 0 10px;
          font-weight: bold;
          box-sizing: border-box;
          background: url(${require('@/assets/img/playpanel_bg.png')}) repeat-y;
          border-radius: 5px 5px 0 0;
          position: fixed;
          overflow: hidden;
        }
        .list-item {
          display: flex;
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          &:nth-child(1) {
            margin-top: 36px;
          }
          &:nth-child(1) .num {
            color: #c10d0c;
          }
          &:nth-child(2) .num {
            color: #c10d0c;
          }
          &:nth-child(3) .num {
            color: #c10d0c;
          }
          .num {
            width: 35px;
            text-align: center;
            font-size: 16px;
            color: #666;
          }
          .song-detail {
            width: 170px;
            vertical-align: middle;
            color: #fff;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-wrap: normal;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
`
