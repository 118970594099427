import hyRequest from '@/service'

export function getConstructKey() {
  return hyRequest.get({
    url: '/login/qr/key'
  })
}

export function getConstructBase64(key: string) {
  return hyRequest.get({
    url: '/login/qr/create?key=' + key + '&qrimg=base64'
  })
}

export function getKeyStatus(key: string) {
  return hyRequest.get({
    url: '/login/qr/check?key=' + key + '&noCookie=true'
  })
}

export function getLoginStatus() {
  return hyRequest.get({
    url: '/login/status'
  })
}
export function getUserDetail(id: number) {
  return hyRequest.get({
    url: '/user/detail?uid=' + id
  })
}

export function getUserAccount() {
  return hyRequest.get({
    url: '/user/account'
  })
}
