import styled from 'styled-components'

export const LyircWrapper = styled.div<{ $url: string }>`
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 47px;
  z-index: 99;
  background: url(${require('@/assets/img/lyricbg.jpg')});
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  .aniation {
    transform: translateY(100%);
  }
  .cover {
    width: 100%;
    height: 100%;
    background: url(${(props) => props?.$url}) no-repeat;
    background-position: 0 -100px;
    background-size: 100%;
    display: flex;
    justify-content: center;
    .lyric {
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: 0.2s;
      margin-top: 25%;
      color: #fff;
      p {
        height: 20px;
        line-height: 20px;
        font-size: 12px;
      }
      .active {
        color: yellow;
        font-size: 16px;
      }
    }
  }
`
