import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  background-color: #242424;
  height: 70px;
  box-sizing: border-box;
  border-bottom: 1px solid #000;
  line-height: 70px;
  min-width: 1100px;
  .divider {
    height: 5px;
    background: #c20c0c;
  }
  .top {
    width: 1100px;
    margin: 0 auto;
    color: #fff;
    font-size: 14px;
    height: 70px;
    .logo {
      cursor: pointer;
      float: left;
      width: 176px;
      height: 69px;
      background-position: 0 0;
      text-indent: -9999px;
      a {
        display: block;
        padding: 0 20px;
        color: #fff;
      }
    }
    .left {
      float: left;
      display: block;
      display: flex;
      .link {
        height: 70px;
        line-height: 70px;
        font-size: 14px;
        a {
          color: #ccc;
          padding: 0 19px;
          display: block;
        }
        .active {
          background-color: #000;
          position: relative;
        }
        .active::after {
          content: '';
          position: absolute;
          background-image: url(${require('@/assets/img/sprite_01.png')});
          width: 12px;
          height: 7px;
          bottom: -1px;
          left: 50%;
          transform: translate(-50%, 0);
          background-position: -226px 0;
        }
      }
    }
    .right {
      float: right;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      .center {
        width: 80px;
        margin: 0 15px;
        border: 1px solid #4f4f4f;
        color: #bbb;
        height: 32px;
        line-height: 32px;
        border-radius: 20px;
        text-align: center;
        cursor: pointer;
        font-size: 12px;
        &:hover {
          color: #ccc;
          border: 1px solid #ccc;
        }
      }
      .input {
        border-radius: 100px !important;
      }
      .login {
        cursor: pointer;
        font-size: 12px;
        color: #666;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`
